import React from 'react';
import { Attachment } from 'src/api/generated';
import AudioMedia from '../../../atoms/media/audio-media';
import FileMedia from '../../../atoms/media/file-media';
import ImageMediaModal from '../../media/image-media-modal';
import VideoMediaModal from '../../media/video-media-modal';

interface AttachmentListProps {
  attachments: Attachment[];
}

const getFileNameFromUrl = (url: string): string => {
  try {
    const urlPath = new URL(url).pathname;
    const fileName = urlPath.split('/').pop();
    return fileName || 'document';
  } catch {
    return 'document';
  }
};

const AttachmentList: React.FC<AttachmentListProps> = ({
  attachments = [],
}) => {
  if (attachments.length === 0) return null;

  const isSingleAttachment = attachments.length === 1;
  const gridClasses = `grid ${
    isSingleAttachment ? 'justify-center' : 'grid-cols-2 gap-2'
  }`;

  return (
    <div className={gridClasses}>
      {attachments.map((attachment) => {
        const { id, type, url } = attachment;

        switch (type) {
          case 'image':
            return <ImageMediaModal key={id} url={url} />;
          case 'video':
            return <VideoMediaModal key={id} url={url} />;
          case 'audio':
            return <AudioMedia key={id} url={url} />;
          case 'file':
            return (
              <FileMedia
                key={id}
                url={url}
                fileName={getFileNameFromUrl(url)}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export default AttachmentList;
