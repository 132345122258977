import React from 'react';
import { FiDownload } from 'react-icons/fi';

interface FileMediaProps {
  url: string;
  fileName?: string;
  containerClassName?: string;
}

export const FileMedia: React.FC<FileMediaProps> = ({
  url,
  fileName = 'Download File',
  containerClassName = '',
}) => {
  const containerClasses = `
    flex items-center justify-between p-4 rounded-lg bg-gray-50 hover:bg-gray-100 
    transition-colors space-x-3 ${containerClassName}
  `;

  const handleDownload = () => {
    window.open(url, '_blank');
  };

  return (
    <div
      className={containerClasses}
      onClick={handleDownload}
      role="button"
      tabIndex={0}
    >
      <span className="text-sm text-blue-600 truncate max-w-[200px]">
        {fileName}
      </span>
      <FiDownload className="w-5 h-5 text-blue-600" />
    </div>
  );
};

export default FileMedia;
